var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"loading":_vm.isLoading,"search":_vm.search,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Search")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search row","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("New User")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"User name","outlined":"","hide-details":""},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"User email","disabled":_vm.editedIndex > -1,"outlined":"","hide-details":""},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"label":"Preferred language","items":[
                        { title: 'EN', value: 'en' },
                        { title: 'DE', value: 'de' },
                        { title: 'HU', value: 'hu' },
                        { title: 'SK', value: 'sk' } ],"item-text":"title","item-value":"value","outlined":"","hide-details":""},model:{value:(_vm.editedItem.preferred),callback:function ($$v) {_vm.$set(_vm.editedItem, "preferred", $$v)},expression:"editedItem.preferred"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Dark theme","items":[
                        { title: 'Yes', value: true },
                        { title: 'No', value: false } ],"item-text":"title","item-value":"value","outlined":"","hide-details":""},model:{value:(_vm.editedItem.useDarkTheme),callback:function ($$v) {_vm.$set(_vm.editedItem, "useDarkTheme", $$v)},expression:"editedItem.useDarkTheme"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"User role","items":[
                        { title: 'Manager', value: 'manager' },
                        { title: 'Developer', value: 'developer' },
                        { title: 'Editor', value: 'editor' },
                        { title: 'Guest', value: 'guest' } ],"item-text":"title","item-value":"value","outlined":"","hide-details":""},model:{value:(_vm.editedItem.role),callback:function ($$v) {_vm.$set(_vm.editedItem, "role", $$v)},expression:"editedItem.role"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":"","large":""},on:{"click":_vm.save}},[_vm._v("Save")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","large":""},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
                      var item = ref.item;
return [_c('a',{staticClass:"font-weight-bold text-decoration-none white--text",class:{
          'error-item': item.role === 'admin',
          'primary-item': item.role !== 'admin',
        },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.email",fn:function(ref){
        var item = ref.item;
return [_c('a',{staticClass:"font-weight-regular text-decoration-none white--text",class:{
          'error-item': item.role === 'admin',
          'primary-item': item.role !== 'admin',
        },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(("" + (item.role.slice(0, 1).toUpperCase()) + (item.role.slice(1)))))])]}},{key:"item.useDarkTheme",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.useDarkTheme ? 'Yes' : 'No')+" ")]}},{key:"item.projects",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.projects && item.projects.length ? item.projects.join(',') : 'All')+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","disabled":item.role === 'admin',"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"color":"primary","disabled":item.role === 'admin',"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }