<template>
  <v-card class="pa-5" elevation="0">
    <v-data-table :headers="headers" :items="rows" :loading="isLoading" :search="search" sort-by="name">
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>Search</v-toolbar-title>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search row"
            single-line
            hide-details
          ></v-text-field>

          <v-divider class="mx-4" inset vertical></v-divider>

          <v-dialog v-model="dialog">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" class="mb-2" dark v-bind="attrs" v-on="on">New User</v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field v-model="editedItem.name" label="User name" outlined hide-details></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="User email"
                        :disabled="editedIndex > -1"
                        outlined
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="editedItem.preferred"
                        label="Preferred language"
                        :items="[
                          { title: 'EN', value: 'en' },
                          { title: 'DE', value: 'de' },
                          { title: 'HU', value: 'hu' },
                          { title: 'SK', value: 'sk' },
                        ]"
                        item-text="title"
                        item-value="value"
                        outlined
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="editedItem.useDarkTheme"
                        label="Dark theme"
                        :items="[
                          { title: 'Yes', value: true },
                          { title: 'No', value: false },
                        ]"
                        item-text="title"
                        item-value="value"
                        outlined
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="editedItem.role"
                        label="User role"
                        :items="[
                          { title: 'Manager', value: 'manager' },
                          { title: 'Developer', value: 'developer' },
                          { title: 'Editor', value: 'editor' },
                          { title: 'Guest', value: 'guest' },
                        ]"
                        item-text="title"
                        item-value="value"
                        outlined
                        hide-details
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" text large @click="save">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" text large @click="close">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"> Are you sure you want to delete this item? </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <a
          href="#"
          class="font-weight-bold text-decoration-none white--text"
          :class="{
            'error-item': item.role === 'admin',
            'primary-item': item.role !== 'admin',
          }"
          @click.prevent="editItem(item)"
        >
          {{ item.name }}
        </a>
      </template>

      <template v-slot:item.email="{ item }">
        <a
          href="#"
          class="font-weight-regular text-decoration-none white--text"
          :class="{
            'error-item': item.role === 'admin',
            'primary-item': item.role !== 'admin',
          }"
          @click.prevent="editItem(item)"
        >
          {{ item.email }}
        </a>
      </template>

      <template v-slot:item.role="{ item }">
        <span>{{ `${item.role.slice(0, 1).toUpperCase()}${item.role.slice(1)}` }}</span>
      </template>

      <template v-slot:item.useDarkTheme="{ item }">
        {{ item.useDarkTheme ? 'Yes' : 'No' }}
      </template>

      <template v-slot:item.projects="{ item }">
        {{ item.projects && item.projects.length ? item.projects.join(',') : 'All' }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" color="primary" :disabled="item.role === 'admin'" small @click="editItem(item)">
          mdi-pencil
        </v-icon>

        <v-icon color="primary" :disabled="item.role === 'admin'" small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'UsersSettings',
  data() {
    return {
      search: '',
      headers: [
        { text: 'Name', align: 'start', filterable: true, value: 'name', width: '20%' },
        { text: 'E-mail', align: 'start', filterable: true, value: 'email', width: '20%' },
        { text: 'Role', align: 'start', filterable: true, value: 'role', width: '10%' },
        { text: 'Projects', align: 'start', filterable: true, value: 'projects', width: '25%' },
        { text: 'Preferred', align: 'start', filterable: true, value: 'preferred', width: '10%' },
        {
          text: 'UseDarkTheme',
          align: 'start',
          filterable: true,
          value: 'useDarkTheme',
          width: '10%',
        },
        { text: 'Actions', value: 'actions', filterable: false, sortable: false, width: '5%' },
      ],
      loaded: false,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        name: '',
        email: '',
        role: '',
        preferred: 'en',
        useDarkTheme: true,
        projects: [],
      },
      defaultItem: {
        name: '',
        email: '',
        role: '',
        preferred: 'en',
        useDarkTheme: true,
        projects: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      isManager: 'user/isManager',
      users: 'users/list',
    }),
    isLoading() {
      return !this.loaded;
    },
    formTitle() {
      return `${-1 === this.editedIndex ? 'New' : 'Edit'} User`;
    },
    rows() {
      return this.loaded ? this.users : [];
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.loaded = false;

    const fetchUsers = this.isManager ? this.fetchUsers() : Promise.resolve();

    Promise.all([fetchUsers])
      .then(() => {
        setTimeout(() => {
          this.loaded = true;
        }, 800);
      })
      .catch(() => {
        this.showAlert({
          message: 'Error occurred while fetching users',
        });
      });
  },
  methods: {
    ...mapActions({
      showAlert: 'alert/show',
      fetchUsers: 'users/fetchUsers',
      addUser: 'users/addUser',
      updateUser: 'users/updateUser',
      deleteUser: 'users/deleteUser',
    }),
    editItem(item) {
      if ('admin' === item.role) {
        return;
      }

      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      if ('admin' === item.role) {
        return;
      }

      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      try {
        await this.deleteUser(this.editedItem);
        this.closeDelete();
      } catch (e) {
        this.showAlert({
          message: e.message,
        });
        this.closeDelete();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      try {
        if (-1 < this.editedIndex) {
          this.updateUser(this.editedItem);
        } else {
          this.addUser(this.editedItem);
        }
      } catch (e) {
        this.showAlert({
          message: e.message,
        });
      }

      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.primary-item {
  color: var(--v-primary-base) !important;
}
.error-item {
  color: var(--v-secondary-base) !important;
}
</style>
